import mutations from './mutations';
import actions from './actions';
import getters from './getters';
const buildStore = {
    state: {
        isBuildingId: 0,
        isBuildingName: '',
        isBuildingChange: '',
        isRegisterNews: ''
    },
    mutations,
    actions,
    getters
};

export default buildStore;
