export default {
    'setLoggedIn'(context, isLoggedIn) {
        context.commit('loggedIn', isLoggedIn)
    },
    'setsUrlAvatar'(context, isUrlAvatar) {
        context.commit('urlAvatar', isUrlAvatar)
    },
    'setLoading'(context, isUrlAvatar) {
        context.commit('loading', isUrlAvatar)
    },
};
