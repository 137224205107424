export default {
    'setBuildingId'(context, isBuildingId) {
        context.commit('isBuildingId', isBuildingId)
    },
    'setBuildingName'(context, isBuildingName) {
        context.commit('isBuildingName', isBuildingName)
    },
    'setBuildingChange'(context, isBuildingChange) {
        context.commit('isBuildingChange', isBuildingChange)
    },
    'setRegisterNews'(context, isRegisterNews) {
        context.commit('isRegisterNews', isRegisterNews)
    }
};
