<template>
    <router-view/>
</template>

<style>
#app {
  font-family: 'Barlow', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}

</style>