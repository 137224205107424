<template>
	<el-dropdown trigger="click">
		<span class="el-dropdown-link nav-link">
			<i class="far fa-bell"></i>
			<span class="badge badge-warning navbar-badge">15</span>
		</span>
		<template #dropdown>
			<el-dropdown-menu class="dropdown-custom">
				<el-dropdown-item class="dropdown-item dropdown-header">15 Notifications</el-dropdown-item>
				<el-dropdown-item class="dropdown-item">
					<p>
						<i class="ico fas fa-envelope mr-2"></i>
						<span class="txt">4 new messages</span>
					</p>
					<span class="text-muted text-sm">3 mins</span>
				</el-dropdown-item>
				<el-dropdown-item class="dropdown-item">
					<p>
						<i class="ico fas fa-users mr-2"></i>
						<span>8 friend requests</span>
					</p>
					<span class="float-right text-muted text-sm">12 hours</span>
				</el-dropdown-item>
				<el-dropdown-item class="dropdown-item">
					<p>
						<i class="ico fas fa-file mr-2"></i>
						<span>3 new reports</span>
					</p>
					<span class="float-right text-muted text-sm">2 days</span>
				</el-dropdown-item>
				<el-dropdown-item class="dropdown-item dropdown-footer">See All Messages</el-dropdown-item>
			</el-dropdown-menu>
		</template>
	</el-dropdown>
</template>

<style scoped>
.nav-link {
	cursor: pointer;
}
.dropdown-item-title .text-danger i {
	color: #dc3545;
}
.dropdown-item-title .text-warning i {
	color: #ffc107;
}
.dropdown-item-title .text-muted i {
	color: #6c757d;
}
</style>