export const USER = 'user'
export const USER_LOGINED = 'isUser'
export const TOKEN = 'token'
export const TOKEN_GOOGLE = 'token_google'
export const SETTING_NAVBAR_MODE = 'settingNavbarMode'
export const SETTING_SIDEBAR_MODE = 'settingSidebarMode'
export const SETTING_BREAKCRUMB = 'setBreadcrumb'
export const BUILDING_ID = 'buildingId'
export const BUILDING_NAME = 'buildingname'
export const ROOM_ID = 'roomId'
export const ROOM_NAME = 'roomName'
export const BUILDING_ADDRESS = 'buildAddress'
export const CONTRACT_ID = 'contractId'
export const CONTRACT_NAME = 'contractName'
export const RECEIPT_ID = 'receiptId'
export const USER_ROLES = 'userRole'
export const USER_TYPE = 'userType'
export const USER_RANK = 'userRank'
export const FULL_NAME = 'full_name'
export const EMAIL_REGISTER_NOTIFICATION = 'emaiNotification'
export const COIN_VALUE = 'coinValue'
export const ROULETTE_ITEMS_COOKIE = 'rouletteItems'
export const NEWS_ID = 'newsId'
export const PRODUCT_SYNC_ID = 'productSyncId'
export const ROULETTE_POINT_COOKIE = 'roulettePoint'
export const USER_POINT_COOKIE = 'userPoint'
export const USER_REGISTER_COOKIE = 'userRegister'
export const USER_NUMBER_RESEND = 'userNumberResend'
export const USER_USERNAME_COOKIE = 'userName'
export const USER_SESSION_ID_COOKIE = 'sesionId'


