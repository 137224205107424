const client = {
  path: "",
  name: "indexHome",
  component: () => import("../views/client/Index.vue"),
  meta: {
    requiresUnauth: true,
  },
  children: [
    {
      path: "",
      name: "LoginIndex",
      component: () =>  import('../views/client/user/Login.vue'),
      meta: {
        requiresAuth: false,
      },
    },
  
    {
      path: '/login',
      name: 'Login',
      component: () =>
        import('../views/client/user/Login.vue'),
      meta: {
        requiresUnauth: true
      }
    },
  ],
};

export default client;
