export default {
    isBuildingId(state, isBuildingId) {
        state.isBuildingId = isBuildingId
    },
    isBuildingName(state, isBuildingName) {
        state.isBuildingName = isBuildingName
    },
    isBuildingChange(state, isBuildingChange) {
        state.isBuildingChange = isBuildingChange
    },
    isRegisterNews(state, isRegisterNews) {
        state.isRegisterNews = isRegisterNews
    },
    
};
