import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import VueFormWizard from 'vue3-form-wizard'
import Antd from 'ant-design-vue';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueCookies from 'vue3-cookies'
import { useCookies } from "vue3-cookies"
import serviceData from './services/servicesApi'
import { GOOGLE_MAP_API } from "@/config/app"
import VueTelInput from 'vue3-tel-input'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Vue3Signature from "vue3-signature"
import VueEasyLightbox from 'vue-easy-lightbox'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueQrcodeReader from 'vue3-qrcode-reader'
import QrReader from 'vue3-qr-reader';
/** Global Components */
import './global.js'
import JQuery from 'jquery'
import i18n from '../createI18n'
import mitt from 'mitt';
import { Roulette } from 'vue3-roulette'
/** import file common API */
/** Vuetify */
// import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives
})
const { cookies } = useCookies()
const app = createApp(App)
const emitter = mitt()
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.config.globalProperties._$ = JQuery
app.config.globalProperties._cookies = cookies
app.config.globalProperties._lenguage = i18n
app.config.globalProperties._router = router
app.use(VueCookies, {
    expireTimes: "8h"
});
serviceData.init()
app.use(router)
    .use(store)
    .use(ElementPlus)
    .use(Antd)
    .use(VueFormWizard)
    .use(i18n)
    .use(VueCookies)
    .use(VueTelInput)
    .use(Vue3Signature)
    .use(VueEasyLightbox)
    .use(QrReader)
    .use(VueQrcodeReader)
    .use(Roulette)
    .use(vuetify)
    .use(VueReCaptcha, { siteKey: "6LeTwbQgAAAAANiCbIiNZmEZGB2kXsdBzRwi0FsQ" })
    .provide('emitter', emitter)
    .mount('#app')
