export default {
    loggedIn(state, isLoggedIn) {
        state.isLoggedIn = isLoggedIn
    },
    urlAvatar(state, isUrlAvatar) {
        state.isUrlAvatar = isUrlAvatar
    },
    loading(state, isLoading) {
        state.isLoading = isLoading
    },
};
