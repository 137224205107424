export const userStatus = Object.freeze({
    zero: 'Khóa',
    one: 'Đang hoạt động',
    two: 'Cập nhật mật khẩu',
    three: 'Chưa kích hoạt',
})

export const requestStatus = Object.freeze({
    1: 'Đã duyệt',
    2: 'Từ chối duyệt',
    3: 'Chờ phê duyệt',
})

export const technicalType = [
    {key: 1,  value: 'Đang có sự cố'},
    {key: 2,  value: 'Đang xử lý'},
    {key: 3,  value: 'Ngừng xử lý'},
    {key: 4,  value: 'Đã xử lý'},
    {key: 5,  value: 'Xong'}
]
// export const roomStatus = Object.freeze({
//     0: 'Phòng trống',
//     1: 'Phòng đang ở',
//     2: 'Phòng báo kết thúc HĐ',
//     3: 'Phòng đang cọc giữ chỗ',
//     4: 'Phòng đang nợ tiển'
// })

export const roomStatus = [
    {key: 0, value: 'Phòng trống'},
    {key: 1, value: 'Phòng đang ở'},
    {key: 2, value: 'Phòng báo kết thúc HĐ'},
    {key: 3, value: 'Phòng đang cọc giữ chỗ'},
    {key: 4, value: 'Phòng đang nợ tiển'}
]

export const contractType = [
    {code: 'HD', name: 'Hợp đồng'},
    {code: 'DC', name: 'Đặt cọc'}
]

export const durationContract = [
    {key: 1, value: '1 tháng'},
    {key: 2, value: '2 tháng'},
    {key: 3, value: '3 tháng'},
    {key: 4, value: '4 tháng'},
    {key: 5, value: '5 tháng'},
    {key: 6, value: '6 tháng'},
    {key: 7, value: '7 tháng'},
    {key: 8, value: '8 tháng'},
    {key: 9, value: '9 tháng'},
    {key: 10, value: '10 tháng'},
    {key: 11, value: '11 tháng'},
    {key: 12, value: '1 năm'},
    {key: 18, value: '1.5 năm'},
    {key: 24, value: '2 năm'},
    {key: 30, value: '2.5 năm'},
    {key: 36, value: '3 năm'},
    {key: 42, value: '3.5 năm'},
    {key: 48, value: '4 năm'},
    {key: 54, value: '4.5 năm'},
    {key: 60, value: '5 năm'},
    {key: 120, value: '10 năm'},
]

export const durationDeposit = [
    {key: 1, value: 'Tiền cọc các phòng = 1 tháng tiền phòng'},
    {key: 2, value: 'Tiền cọc các phòng = 2 tháng tiền phòng'},
    {key: 3, value: 'Tiền cọc các phòng = 3 tháng tiền phòng'},
    {key: 4, value: 'Tiền cọc các phòng = 4 tháng tiền phòng'},
    {key: 5, value: 'Tiền cọc các phòng = 5 tháng tiền phòng'},
    {key: 6, value: 'Tiền cọc các phòng = 6 tháng tiền phòng'},
    {key: 7, value: 'Tiền cọc các phòng = 7 tháng tiền phòng'},
    {key: 8, value: 'Tiền cọc các phòng = 8 tháng tiền phòng'},
    {key: 9, value: 'Tiền cọc các phòng = 9 tháng tiền phòng'},
    {key: 10, value: 'Tiền cọc các phòng = 10 tháng tiền phòng'},
    {key: 11, value: 'Tiền cọc các phòng = 11 tháng tiền phòng'},
    {key: 12, value: 'Tiền cọc các phòng = 1 năm tiền phòng'},
    {key: 18, value: 'Tiền cọc các phòng = 1.5 năm tiền phòng'},
    {key: 24, value: 'Tiền cọc các phòng = 2 năm tiền phòng'},
    {key: 30, value: 'Tiền cọc các phòng = 2.5 năm tiền phòng'},
    {key: 36, value: 'Tiền cọc các phòng = 3 năm tiền phòng'},
    {key: 42, value: 'Tiền cọc các phòng = 3.5 năm tiền phòng'},
    {key: 48, value: 'Tiền cọc các phòng = 4 năm tiền phòng'},
    {key: 54, value: 'Tiền cọc các phòng = 4.5 năm tiền phòng'},
    {key: 60, value: 'Tiền cọc các phòng = 5 năm tiền phòng'},
    {key: 120, value: 'Tiền cọc các phòng = 10 năm tiền phòng'},
]

export const genderType = [
    {key: 'M', value: 'Nam'},
    {key: 'F', value: 'Nữ'},
    {key: 'O', value: 'Khác'}
]

export const servicesType = [
    {key: '1', value: 'kWh'},
    {key: '2', value: 'Khối'},
    {key: '3', value: 'Tháng'},
    {key: '4', value: 'Người'},
    {key: '5', value: 'Chiếc'},
    {key: '6', value: 'Lần'},
    {key: '7', value: 'Cái'}
]

export const serviceStatus = [
    {key: 1, value: 'Sử dụng'},
    {key: 0, value: 'Ngừng sử dụng'}
]

export const dateInMonth = [
    {key: 1, value: 'Ngày 1'},
    {key: 2, value: 'Ngày 2'},
    {key: 3, value: 'Ngày 3'},
    {key: 4, value: 'Ngày 4'},
    {key: 5, value: 'Ngày 5'},
    {key: 6, value: 'Ngày 6'},
    {key: 7, value: 'Ngày 7'},
    {key: 8, value: 'Ngày 8'},
    {key: 9, value: 'Ngày 9'},
    {key: 10, value: 'Ngày 10'},
    {key: 11, value: 'Ngày 11'},
    {key: 12, value: 'Ngày 12'},
    {key: 13, value: 'Ngày 13'},
    {key: 14, value: 'Ngày 14'},
    {key: 15, value: 'Ngày 15'},
    {key: 16, value: 'Ngày 16'},
    {key: 17, value: 'Ngày 17'},
    {key: 18, value: 'Ngày 18'},
    {key: 19, value: 'Ngày 19'},
    {key: 20, value: 'Ngày 20'},
    {key: 21, value: 'Ngày 21'},
    {key: 22, value: 'Ngày 22'},
    {key: 23, value: 'Ngày 23'},
    {key: 24, value: 'Ngày 24'},
    {key: 25, value: 'Ngày 25'},
    {key: 26, value: 'Ngày 26'},
    {key: 27, value: 'Ngày 27'},
    {key: 28, value: 'Ngày 28'},
    {key: 29, value: 'Ngày 29'},
    {key: 20, value: 'Ngày 30'},
    {key: 31, value: 'Ngày 31'}
]

export const moneyName = [
    {key: '1', value: 'một'},
    {key: '2', value: 'hai'},
    {key: '3', value: 'ba'},
    {key: '4', value: 'bốn'},
    {key: '5', value: 'năm'},
    {key: '6', value: 'sáu'},
    {key: '7', value: 'bảy'},
    {key: '8', value: 'tám'},
    {key: '9', value: 'chín'}
]
export const moneyDvi = [
    {key: '1',  value: 'đồng'},
    {key: '2',  value: 'mươi'},
    {key: '3',  value: 'trăm'},
    {key: '4',  value: 'nghìn'},
    {key: '5',  value: 'mươi'},
    {key: '6',  value: 'trăm'},
    {key: '7',  value: 'triệu'},
    {key: '8',  value: 'mươi'},
    {key: '9',  value: 'trăm'},
    {key: '10',  value: 'tỷ'},
    {key: '11',  value: 'mươi'},
    {key: '12',  value: 'trăm'},
    {key: '13',  value: 'nghìn'},
    {key: '14',  value: 'mươi'},
    {key: '15',  value: 'trăm'}
]

export const departmentArr = [
    {key: 1, value: 'Kinh doanh'},
    {key: 2, value: 'Nghiệp vụ'},
    {key: 3, value: 'Khối văn phòng'},
    {key: 4, value: 'Khối quản lý'}
]

export const positionArr = [
    {key: 1, value: 'Khách hàng'},
    {key: 2, value: 'Nhân viên kinh doanh'},
    {key: 3, value: 'Trưởng nhóm kinh doanh'},
    {key: 4, value: 'Quản lý'}
]
export const positionArrFree = Object.freeze({
    one: 'Khách hàng',
    two: 'Nhân viên kinh doanh',
    three: 'Trưởng nhóm kinh doanh',
    four: 'Quản lý'
})