import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import {IAuthModule} from '../../interface/state';
const userStore: IAuthModule = {
    state: {
        isLoggedIn: false,
        isUrlAvatar: '',
        isLoading: false,
    },
    mutations,
    actions,
    getters
};

export default userStore;
